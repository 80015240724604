<template>
  <BaseUser />
</template>

<script>
import BaseUser from "../module/baseUser";
export default {
  components: {
    BaseUser,
  },
};
</script>

<style>
</style>